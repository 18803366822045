// import store from "../redux/store/store";
// import { generateTokenApi } from "../api/ApiService";
// import { logout } from "../redux/action/LoginAction/LoginAction";

// export const validateToken = async () => {
//   const state = store.getState();
//   const accessTokenExpiry = state.loginData.loginSuccesData.accessTokenExpiry;

//   const currentTime = Math.floor(new Date().getTime() / 1000);

//   if (currentTime > accessTokenExpiry) {
//     try {
//       const response = await generateTokenApi();
//       if (response?.data?.token?.accessToken) {
//         store.dispatch({ type: 'GENERATE_TOKEN', payload: response });
//         return response.data.token.accessToken;
//       } else {
//         store.dispatch(logout());
//         return null;
//       }
//     } catch (error) {
//       console.error("Error generating new token:", error);
//       store.dispatch(logout());
//       return null;
//     }
//   } else {
//     return state.loginData.loginSuccesData.accessToken;
//   }
// };

import store from "../redux/store/store";
import { logout } from "../redux/action/LoginAction/LoginAction";

export const validateToken = async () => {
  const state = store.getState();
  const accessTokenExpiry = state.loginData.loginSuccesData?.accessTokenExpiry;
  const accessToken = state.loginData.loginSuccesData?.accessToken;

  if (!accessToken || !accessTokenExpiry) {
    return null;
  }

  const currentTime = Math.floor(new Date().getTime() / 1000);
  console.log(currentTime, "currentttttttttttttt");
  const timeRemaining = accessTokenExpiry - currentTime;
  console.log(timeRemaining, "timeeeeeeeeeeeeeeeeee");

  // If token is expired
  if (timeRemaining <= 0) {
    console.log("Token expired, logging out");
    if (!window.location.pathname.includes("/login")) {
      store.dispatch(logout());
      window.location.href = "/login";
    }
    return null;
  }

  // If token is about to expire in the next 5 minutes, show warning
  if (timeRemaining < 300) {
    console.warn(`Token will expire in ${timeRemaining} seconds`);
  }

  return accessToken;
};
